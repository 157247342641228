<template>
  <v-card>
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text
      ><v-container>
        <v-row>
          <v-col
            v-for="category in categories"
            :key="category.id"
            cols="6"
            md="2"
          >
            <v-checkbox
              v-model="selectedCategories"
              :label="category.name"
              color="red"
              :value="category.id"
              @change="emitSelectedCategories"
            ></v-checkbox>
          </v-col>
        </v-row> </v-container
    ></v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    selectedCategories: [],
  }),

  methods: {
    emitSelectedCategories() {
      this.$emit('selectedCategories', this.selectedCategories);
    },
  },
};
</script>
