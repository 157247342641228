<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="4">
        <BaseMonthPicker
          :value.sync="startMonthYear"
          :label="$t('startMonth')"
          :showMonthPicker.sync="showStartMonthPicker"
        />
      </v-col>
      <v-col cols="12" lg="4">
        <BaseMonthPicker
          :value.sync="endMonthYear"
          :label="$t('endMonth')"
          :showMonthPicker.sync="showEndMonthPicker"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="8">
        <v-card class="pt-6 pb-4">
          <SummaryBarChart
            :options="barChartOptions"
            :chartData="expenseSumChartData"
          />
        </v-card>
      </v-col>
      <v-col cols="12" lg="4">
        <ExpenseStats :stats="expensesStats" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <CategorySelector
          :categories="categoriesData"
          :title="$t('categorySelectorTitle')"
          @selectedCategories="setExcludeCategiries"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CategorySelector from '@/components/forms/CategorySelector';
import exploreViewMixin from '@/mixins/exploreViewMixin';

export default {
  components: {
    CategorySelector,
  },

  mixins: [exploreViewMixin],

  data: () => ({
    excludedCategories: [],
  }),

  created() {
    if (!this.categoriesData.length) {
      this.getCategoriesData().then(() => {
        this.fetchExpensesSum();
      });
    } else {
      this.fetchExpensesSum();
    }
  },

  methods: {
    setExcludeCategiries(categories) {
      this.excludedCategories = categories;

      this.fetchExpensesSum();
    },
  },
};
</script>
